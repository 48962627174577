export const namespaced = true
import store from '@/store'
import router from '@/router'
import { useToast } from 'vue-toastification'
import { comp_play_error_file, comp_play_success_file } from './utils';
const toast = useToast()

export default {
    state : {
        authuser :'',
        authlocation : '',
        isAuth:'',
		returnvalue:'',
        userip:'',
        clientlocation:'',
        clientdevice:'',
        hospital:'',
    },
    
    getters : {
        authuser: (state )=> state.authuser,
        authlocation: (state )=> state.authlocation,
		returnvalue: (state )=> state.returnvalue,
        isAuth: state => !!state.authuser,
        userip: (state )=> state.userip,
        clientlocation:(state )=> state.clientlocation,
        clientdevice:(state )=> state.clientdevice,
        hospital:(state )=> state.hospital,
       
    },


    mutations : { 
        setauthuser :(state,authuser ) => (state.authuser = authuser), 
        setauthlocation :(state,authlocation ) => (state.authlocation = authlocation), 
        setisAuth :(state,isAuth ) => (state.isAuth = isAuth), 
        setreturnvalue :(state,returnvalue ) => (state.returnvalue = returnvalue),
        setuserip :(state,userip ) => (state.userip = userip),
        setclientlocation :(state,clientlocation ) => (state.clientlocation = clientlocation),
        setclientdevice :(state,clientdevice ) => (state.clientdevice = clientdevice),
        sethospital :(state,hospital ) => (state.hospital = hospital),
    },

    actions : {


        getuseripaddress({ commit }){
            fetch('https://api.ipify.org?format=json')
                .then(x => x.json())
                .then(({ ip }) => {
                    commit('setuserip', ip)
                });
        },

        getuserlocation({ commit, getters }){
            console.log(getters.userip)
            const url = "https://ipapi.co/"+ getters.userip +"/json/";
            const options = {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
            },
            body: JSON.stringify({
                a: 10,
                b: 20,
            }),
            };
            fetch(url, options)
            .then((response) => response.json())
            .then((data) => {
                commit('setclientlocation', data)
            });
        },


        async register( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'register')
            return new Promise((resolve, reject) => {
            axios.post("/auth/register", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.data){
                    localStorage.setItem('hospitalmanagementsystem'+ response.data.data.id, response.data.authtoken.accessToken)
                    commit('setauthuser', response.data.data)
                    commit('sethospital', response.data.data.hospital[0])
                    toast.success(response.data.msg)
                    resolve(response)
                 
                }  
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async login( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'login')
            return new Promise((resolve, reject) => {
            axios.post("/auth/login", data)
            .then(async response => {	
                commit('setloader', false)
                if(response.data.data){
                    localStorage.setItem('hospitalmanagementsystem'+ response.data.data.id, response.data.authtoken.accessToken)
                    commit('setauthuser', response.data.data)
                    commit('sethospital', response.data.data.hospital[0])
                    toast.success(response.data.msg)
                    resolve(response)
                 
                } 
            })
            .catch(async error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                await comp_play_error_file(); 
                reject(error)
            });
            });	       
        },

        async forgot_password( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'request_otp')
            return new Promise((resolve, reject) => {
            axios.post("/auth/forgot_password", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                    toast.success(response.data.msg)
                }
                resolve(response)
            })
            .catch(async error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                await comp_play_error_file(); 
                reject(error)
            });
            });	       
        },

        async reset_password( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'request_otp')
            return new Promise((resolve, reject) => {
            axios.post("/auth/reset_password", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                    toast.success(response.data.msg)
                }
                resolve(response)
            })
            .catch(async error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                await comp_play_error_file(); 
                reject(error)
            });
            });	       
        },

        async request_otp( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'request_otp')
            return new Promise((resolve, reject) => {
            axios.post("/auth/requestotp", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                    toast.success(response.data.msg)
                }
                resolve(response)
            })
            .catch(async error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                await comp_play_error_file(); 
                reject(error)
            });
            });	       
        },

        async verify_code( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'verify_code')
            return new Promise((resolve, reject) => {
            axios.post("/auth/verify_code", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.data){
                    localStorage.setItem('hospitalmanagementsystem'+ response.data.data.id, response.data.authtoken.accessToken)
                    commit('setauthuser', response.data.data)
                    commit('sethospital', response.data.data.hospital[0])
                    toast.success(response.data.msg)
                    resolve(response)
                 
                } 
            })
            .catch(async error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                await comp_play_error_file(); 
                reject(error)
            });
            });	       
        },


        async getauthuser( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'loginuser')
            return new Promise((resolve, reject) => {
            axios.post("/auth/authuser", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.data){
                    localStorage.setItem('hospitalmanagementsystem'+ response.data.data.id, response.data.authtoken.accessToken)
                    commit('setauthuser', response.data.data)
                    commit('sethospital', response.data.data.hospital[0])
                    toast.success(response.data.msg)
                    resolve(response)
                 
                } 
            })
            .catch(async error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                await comp_play_error_file(); 
                reject(error)
            });
            });	       
        },


        async logoutuser( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'logoutuser')
            return new Promise((resolve, reject) => {
            axios.post("/auth/logout", data)
            .then(response => {	
                console.log(response);
                commit('setloader', false)
                localStorage.clear()
                commit('setauthuser', '')
                toast.success(response.data.msg)
                resolve(response)

            })
            .catch(async error=> {
                commit('setloader', false)
                localStorage.removeItem('hospitalmanagementsystem')
                commit('setauthuser', '')
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                await comp_play_error_file(); 
                reject(error)
            });
            });	       
        },


        async update_user_account( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_user_account')
            return new Promise((resolve, reject) => {
            axios.post("/auth/updateaccount", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.data){
                    localStorage.setItem('hospitalmanagementsystem'+ response.data.data.id, response.data.authtoken.accessToken)
                    commit('setauthuser', response.data.data)
                    commit('sethospital', response.data.data.hospital[0])
                    toast.success(response.data.msg)
                    resolve(response)
                 
                } 
            })
            .catch(async error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                await comp_play_error_file(); 
                reject(error)
            });
            });	       
        },


        async updateprofileimage( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'updateprofileimage')
            return new Promise((resolve, reject) => {
            axios.post("/auth/updateprofilepic", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.data){
                    localStorage.setItem('hospitalmanagementsystem'+ response.data.data.id, response.data.authtoken.accessToken)
                    commit('setauthuser', response.data.data)
                    commit('sethospital', response.data.data.hospital[0])
                    toast.success(response.data.msg)
                    resolve(response)
                 
                }  
            })
            .catch(async error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                await comp_play_error_file(); 
                reject(error)
            });
            });	       
        },


        async changepassword( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'changepassword')
            return new Promise((resolve, reject) => {
            axios.post("/auth/updatepassword", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.data){
                    localStorage.setItem('hospitalmanagementsystem'+ response.data.data.id, response.data.authtoken.accessToken)
                    commit('setauthuser', response.data.data)
                    commit('sethospital', response.data.data.hospital[0])
                    toast.success(response.data.msg)
                    resolve(response)
                 
                } 
            })
            .catch(async error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                await comp_play_error_file(); 
                reject(error)
            });
            });	       
        },

        async deleteuseraccount( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'deleteuseraccount')
            return new Promise((resolve, reject) => {
            axios.post("/auth/deleteaccount", data)
            .then(response => {	
                commit('setloader', false)
                localStorage.clear()
                commit('setauthuser', '')
                toast.success(response.data.msg)
                resolve(response)
            })
            .catch(async error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                await comp_play_error_file(); 
                reject(error)
            });
            });	       
        },


        //create hospital
        async create_hospital( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_hospital')
            return new Promise((resolve, reject) => {
            axios.post("/auth/hospital/create", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.data){
                    localStorage.setItem('hospitalmanagementsystem'+ response.data.data.id, response.data.authtoken.accessToken)
                    commit('setauthuser', response.data.data)
                    commit('sethospital', response.data.data.hospital[0])
                    toast.success(response.data.msg)
                    resolve(response)
                }  
            })
            .catch(async error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            await comp_play_error_file(error.response.data.msg); 
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
            
                reject(error)
            });
            });	       
        },

    },


    

    
}













