export const namespaced = true 
import { comp_play_error_file, comp_play_success_file, comp_play_delete_file} from './utils';
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        requsition :'', 
        requsitions :'', 
    },
    
    getters : {
        requsition: (state )=> state.requsition,
        requsitions: (state )=> state.requsitions,
    },


    mutations : { 
        setrequsition :(state,requsition ) => (state.requsition = requsition), 
        setrequsitions:(state,requsitions ) => (state.requsitions = requsitions), 
    },

    actions : {

        async make_requisition_request( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'make_requisition_request')
            return new Promise((resolve, reject) => {
            axios.post("/requisitions/create", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                commit('setrequsitions', response.data.data)
                toast.success("Request made Successfully")
                }
                resolve(response)
            })
            .catch(async error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_finance_requsitions( { commit }, { data, page }) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_finance_requsitions')
            commit('setpageloading', 'get_finance_requsitions')
            return new Promise((resolve, reject) => {
            axios.post("/finance/requisitions?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setrequsitions', response.data.data)
                }
                commit('setpageloading', false)
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_my_requsitions( { commit }, { data, page }) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_my_requsitions')
            commit('setpageloading', 'get_my_requsitions')
            return new Promise((resolve, reject) => {
            axios.post("/requisitions?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setrequsitions', response.data.data)
                }
                commit('setpageloading', false)
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_my_approvals_requsitions( { commit }, { data, page }) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_my_approvals_requsitions')
            commit('setpageloading', 'get_my_approvals_requsitions')
            return new Promise((resolve, reject) => {
            axios.post("/requisitions/approvals?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setrequsitions', response.data.data)
                }
                commit('setpageloading', false)
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async make_action_to_requsition( { commit }, {data, id, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'make_action_to_requsition')
            return new Promise((resolve, reject) => {
            axios.post("/requisitions/status/" + id + "?page=" + page, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                commit('setrequsitions', response.data.data)
                toast.success("Request made Successfully")
                }
                resolve(response)
            })
            .catch(async error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async delete_requsition( { commit }, {data, id, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_requsition')
            return new Promise((resolve, reject) => {
            axios.post("/requisitions/delete/" + id + "?page=" + page, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                commit('setrequsitions', response.data.data)
                toast.success("Request Deletec Successfully")
                }
                resolve(response)
            })
            .catch(async error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        






    }


    
}