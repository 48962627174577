<template>
  <div
    class="flex items-center justify-center"
    style="background-color: transparent"
    :style="{ height: setheight ?? '20rem' }"
  >
    <div
      class="flex justify-center items-center space-x-1 text-sm text-gray-700"
    >
      <svg
        fill="none"
        style="height: 6rem; width: 6rem"
        stroke-width="2"
        class="animate-spin"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clip-rule="evenodd"
          d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
          fill="currentColor"
          fill-rule="evenodd"
        />
      </svg>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    loadingvalue: {
      type: [String, Boolean, Number],
      default: "",
    },
    loadingTxt: {
      type: String,
      default: "Loading...",
    },
    className: {
      type: String,
      default: "btn-primary",
    },
    btnText: {
      type: String,
      default: "Submit",
    },
    setheight: {
      type: String,
      default: "30rem",
    },
  },

  computed: {
    ...mapGetters(["loading"]),
  },
};
</script>
