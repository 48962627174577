export const namespaced = true 
import { comp_play_error_file, comp_play_success_file, comp_play_delete_file} from './utils';
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        inventory_items :'', 
        inventory_item :'', 
        inventory_categories :'',
        inventory_issues :'',
        inventorydash :'',
        inventoryplacements :'',
    },
    
    getters : {
        inventory_items: (state )=> state.inventory_items,
        inventory_item: (state )=> state.inventory_item,
        inventory_categories: (state )=> state.inventory_categories,
        inventory_issues: (state )=> state.inventory_issues,
        inventorydash: (state )=> state.inventorydash,
        inventoryplacements: (state )=> state.inventoryplacements,
    },


    mutations : { 
        setinventory_items :(state,inventory_items ) => (state.inventory_items = inventory_items), 
        setinventory_item :(state,inventory_item ) => (state.inventory_item = inventory_item), 
        setinventory_categories :(state,inventory_categories ) => (state.inventory_categories = inventory_categories), 
        setinventory_issues :(state,inventory_issues ) => (state.inventory_issues = inventory_issues), 
        setinventorydash :(state,inventorydash ) => (state.inventorydash = inventorydash), 
        setinventoryplacements :(state,inventoryplacements ) => (state.inventoryplacements = inventoryplacements), 
    },

    actions : {

        async get_hospital_inventory_dash_details( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setpageloading', 'get_hospital_inventory_dash_details')
            return new Promise((resolve, reject) => {
            axios.post("/admin/inventory/dash_data", data)
            .then(response => {	
                commit('setpageloading', false)
                if(response.data){
                commit('setinventorydash', response.data.data)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setpageloading', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        } 
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_hospital_inventory_placements( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setpageloading', 'get_hospital_inventory_placements')
            return new Promise((resolve, reject) => {
            axios.post("/admin/inventory/placements?page=" +page, data)
            .then(response => {	
                commit('setpageloading', false)
                if(response.data){
                   
                commit('setinventoryplacements', response.data.data)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setpageloading', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        } 
                    }
                }
                reject(error)
            });
            });	       
        },

        async create_inventory_item( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_inventory_item')
            return new Promise((resolve, reject) => {
            axios.post("/settings/inventory/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setinventory_items', response.data.data)
                toast.success("Item Added Successfully")
                }
                resolve(response)
                
            })
            .catch(async error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        }
                        
                    }
                }
                
                reject(error)
            });
            });	       
        },


        async get_inventory_items( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_inventory_items')
            return new Promise((resolve, reject) => {
            axios.post("/settings/inventory?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setinventory_items', response.data.data)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_inventory_item( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_inventory_item' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/settings/inventory/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setinventory_item', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_inventory_item( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_inventory_item' + id)
            return new Promise((resolve, reject) => {
            axios.post("/settings/inventory/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setinventory_items', response.data.data)
                toast.success("Item Updated Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async change_inventory_item_status({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_inventory_item_status' + id)
            return new Promise((resolve, reject) => {
                axios.post(`/settings/inventory/status/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setinventory_items', response.data.data)
                        if (data.status == 1) {
                            toast.success("Item Activated Successfully")
                        } else {
                            toast.success("Item Suspended Successfully")
                        }
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if(error.response.data.msg){
                                commit('seterror', error.response.data.msg)
                                comp_play_error_file(error.response.data.msg);
                            }else if(error.response.data.message){
                                commit('seterrors', error.response.data.message)
                                comp_play_error_file(error.response.data.message);
                            }
                        }
                    }
                    reject(error)
                });
            });
        },

        async delete_inventory_item( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_inventory_item' + id)
            return new Promise((resolve, reject) => {
            axios.post("/settings/inventory/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setinventory_items', response.data.data)
                comp_play_delete_file('Item Deleted Successfully');
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        ///categories
        async create_inventory_category( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_inventory_category')
            return new Promise((resolve, reject) => {
            axios.post("/settings/inventory/categories/create", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                commit('setinventory_categories', response.data.data)
                toast.success("Category Added Successfully")
                }
                resolve(response)
                
            })
            .catch(async error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        }
                        
                    }
                }
                
                reject(error)
            });
            });	       
        },

        async get_inventory_categories( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_inventory_categories')
            return new Promise((resolve, reject) => {
            axios.post("settings/inventory/categories?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setinventory_categories', response.data.data)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_inventory_issue_history( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_inventory_issue_history')
            return new Promise((resolve, reject) => {
            axios.post("settings/inventory/issue_history?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setinventory_issues', response.data.data)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async add_stock_to_inventory( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'add_stock_to_inventory')
            return new Promise((resolve, reject) => {
            axios.post("/settings/inventory/stockin/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setinventory_issues', response.data.data)
                toast.success("Stock Added Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async issue_inventory_items( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'issue_inventory_items')
            return new Promise((resolve, reject) => {
            axios.post("/settings/inventory/issueitems", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                commit('setinventory_issues', response.data.data)
                toast.success("Item Issued Successfully")
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        }
                    }
                }
                commit('setloader', false)
                reject(error)
            });
            });	       
        },


        async delete_item_issue_entry( { commit }, {data,page, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_item_issue_entry')
            return new Promise((resolve, reject) => {
            axios.post("/settings/inventory/stockout/delete/"+ id +"?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setinventory_issues', response.data.data)
                toast.success("Item Issue Entry Deleted Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async return_item_issue_entry( { commit }, {data,page, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'return_item_issue_entry')
            return new Promise((resolve, reject) => {
            axios.post("/settings/inventory/stockout/return/"+ id +"?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setinventory_issues', response.data.data)
                toast.success("Item Returned Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async inventory_reverse_return_item( { commit }, {data,page, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'inventory_reverse_return_item')
            return new Promise((resolve, reject) => {
            axios.post("/settings/inventory/stockout/markunreturn/"+ id +"?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setinventory_issues', response.data.data)
                toast.success("Item Returned Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },



 
    }


    
}