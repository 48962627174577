<template>
    <div class="mt-4 mb-2">
      <div
        ref="scrollContainer"
        class="overflow-x-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent"
        tabindex="0"
        @keydown.left="handleKeyScroll(-1)"
        @keydown.right="handleKeyScroll(1)"
        @mousedown="startDragging"
        @mousemove="handleDrag"
        @mouseup="stopDragging"
        @mouseleave="stopDragging"
      >
        <div
          ref="pillsContainer"
          class="flex space-x-2 min-w-max pb-2 select-none"
          :style="{ cursor: isDragging ? 'grabbing' : 'grab' }"
        >
          <button
            v-for="(item, i) in items.data"
            :key="i"
            type="button"
            class="px-3 py-1.5 rounded-md text-sm font-medium text-white transition-colors"
            :class="[
              selectedValue === getDisplayValue(item) ? 'bg-green-900' : 'bg-green-500 hover:bg-green-600'
            ]"
            @click.prevent="handleSelectItem(item)"
          >
            {{ getDisplayValue(item) }}
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onBeforeUnmount } from "vue";
  
  const props = defineProps({
    items: {
      type: Object,
      required: true,
    },
    selectedValue: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      required: true,
      validator: (value) => ["patients", "services", "labtests"].includes(value),
    },
    displayKey: {
      type: String,
      default: "title",
    },
  });
  
  const emit = defineEmits(["select"]);
  
  // Scroll functionality refs
  const scrollContainer = ref(null);
  const isDragging = ref(false);
  const startX = ref(0);
  const scrollLeft = ref(0);
  
  // Method to get display value based on type
  const getDisplayValue = (item) => {
    switch (props.type) {
      case "patients":
        return `${item.first_name} ${item.last_name}`;
      case "services":
      case "labtests":
        return item[props.displayKey];
      default:
        return item[props.displayKey];
    }
  };
  
  // Method to handle item selection
  const handleSelectItem = (item) => {
    emit("select", item);
  };
  
  // Scroll handling
  const handleKeyScroll = (direction) => {
    if (!scrollContainer.value) return;
    const scrollAmount = 100;
    scrollContainer.value.scrollLeft += direction * scrollAmount;
  };
  
  // Start dragging
  const startDragging = (e) => {
    isDragging.value = true;
    startX.value = e.pageX - scrollContainer.value.offsetLeft;
    scrollLeft.value = scrollContainer.value.scrollLeft;
  
    // Add document-wide event listeners
    document.addEventListener("mousemove", handleDrag);
    document.addEventListener("mouseup", stopDragging);
  };
  
  // Handle dragging
  const handleDrag = (e) => {
    if (!isDragging.value) return;
    e.preventDefault();
    const x = e.pageX - scrollContainer.value.offsetLeft;
    const walk = (x - startX.value) * 2; // Adjust sensitivity here
    scrollContainer.value.scrollLeft = scrollLeft.value - walk;
  };
  
  // Stop dragging
  const stopDragging = () => {
    isDragging.value = false;
  
    // Remove document-wide event listeners
    document.removeEventListener("mousemove", handleDrag);
    document.removeEventListener("mouseup", stopDragging);
  };
  
  // Cleanup on component unmount
  onBeforeUnmount(() => {
    document.removeEventListener("mousemove", handleDrag);
    document.removeEventListener("mouseup", stopDragging);
  });
  </script>
  
  <style scoped>
  .overflow-x-auto {
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
  }
  
  .overflow-x-auto::-webkit-scrollbar {
    display: none;
  }
  
  .overflow-x-auto {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  </style>
  