export const namespaced = true 
import { comp_play_error_file, comp_play_success_file, comp_play_delete_file} from './utils';
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        drug_stocks :'', 
        drug_stock :'', 
        drug_stock_ins :'', 
        drug_stock_outs :'', 
    },
    
    getters : {
        drug_stocks: (state )=> state.drug_stocks,
        drug_stock: (state )=> state.drug_stock,
        drug_stock_ins: (state )=> state.drug_stock_ins,
        drug_stock_outs: (state )=> state.drug_stock_outs,
    },


    mutations : { 
        setdrug_stocks :(state,drug_stocks ) => (state.drug_stocks = drug_stocks), 
        setdrug_stock :(state,drug_stock ) => (state.drug_stock = drug_stock),
        setdrug_stock_ins :(state,drug_stock_ins ) => (state.drug_stock_ins = drug_stock_ins),
        setdrug_stock_outs :(state,drug_stock_outs ) => (state.drug_stock_outs = drug_stock_outs), 
    },

    actions : {

        async create_drug_stock( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_drug_stock')
            return new Promise((resolve, reject) => {
            axios.post("/stock/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setdrug_stocks', response.data.data)
                toast.success("Stock Added Successfully")
                }
                resolve(response)
                
            })
            .catch(async error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        }
                        
                    }
                }
                
                reject(error)
            });
            });	       
        },


        async get_drug_stocks( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_drug_stocks')
            return new Promise((resolve, reject) => {
            axios.post("/stock?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setdrug_stocks', response.data.data)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_drug_stock( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setpageloading', 'get_drug_stock')
            return new Promise((resolve, reject) => {
            axios.post("/stock/drug/" + id, data)
            .then(response => {	
                if(response.data){
                commit('setdrug', response.data.data)
                }
                commit('setloader', false)
                commit('setpageloading', false)
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_drug_stock_ins( { commit }, {data, page, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setpageloading', 'get_drug_stock_ins')
            return new Promise((resolve, reject) => {
            axios.post("/stock/drug/in/" + id + "?page=" + page, data)
            .then(response => {	
                if(response.data){
                commit('setdrug_stock_ins', response.data.data)
                }
                commit('setloader', false)
                commit('setpageloading', false)
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_drug_stock_outs( { commit }, {data,page, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setpageloading', 'get_drug_stock_outs')
            return new Promise((resolve, reject) => {
            axios.post("/stock/drug/out/" + id + "?page=" + page, data)
            .then(response => {	
                if(response.data){
                commit('setdrug_stock_outs', response.data.data)
                }
                commit('setloader', false)
                commit('setpageloading', false)
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_drug_stock( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_drug_stock' + id)
            return new Promise((resolve, reject) => {
            axios.post("/stock/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setdrug_stocks', response.data.data)
                toast.success("drug_stock Updated Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async register_stockin_bad_batch( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'register_stockin_bad_batch' + id)
            return new Promise((resolve, reject) => {
            axios.post("/stock/bad_batch/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setdrug_stocks', response.data.data)
                toast.success("Bad Batch Updated Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async change_drug_stock_status({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_drug_stock_status' + id)
            return new Promise((resolve, reject) => {
                axios.post(`/stock/status/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setdrug_stocks', response.data.data)
                        if (data.status == 1) {
                            toast.success("drug_stock Activated Successfully")
                        } else {
                            toast.success("drug_stock Suspended Successfully")
                        }
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if(error.response.data.msg){
                                commit('seterror', error.response.data.msg)
                                comp_play_error_file(error.response.data.msg);
                            }else if(error.response.data.message){
                                commit('seterrors', error.response.data.message)
                                comp_play_error_file(error.response.data.message);
                            }
                        }
                    }
                    reject(error)
                });
            });
        },

        async delete_drug_stock( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_drug_stock' + id)
            return new Promise((resolve, reject) => {
            axios.post("/stock/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setdrug_stocks', response.data.data)
                comp_play_delete_file('drug_stock Deleted Successfully');
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    }


}