export const namespaced = true 
import { comp_play_error_file, comp_play_success_file, comp_play_delete_file} from './utils';
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        finance_dash_data :'', 
        incomes :'', 
    },
    
    getters : {
        finance_dash_data: (state )=> state.finance_dash_data,
        incomes: (state )=> state.incomes,
    },


    mutations : { 
        setfinance_dash_data :(state,finance_dash_data ) => (state.finance_dash_data = finance_dash_data), 
        setincomes:(state,incomes ) => (state.incomes = incomes), 
    },

    actions : {

        async get_finance_dash_data( { commit }, { data, page }) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_finance_dash_data')
            commit('setpageloading', 'get_finance_dash_data')
            return new Promise((resolve, reject) => {
            axios.post("/finance/dash?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setfinance_dash_data', response.data.data)
                }
                commit('setpageloading', false)
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_income_data( { commit }, { data, page }) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_income_data')
            commit('setpageloading', 'get_income_data')
            return new Promise((resolve, reject) => {
            axios.post("/finance/incomes?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setincomes', response.data.data)
                }
                commit('setpageloading', false)
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },




    }


    
}