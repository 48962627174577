export const namespaced = true;
import {
  comp_play_error_file,
  comp_play_success_file,
  comp_play_delete_file,
} from "./utils";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  state: {
    labtests: "",
    labtest: "",
    labdashdata: "",
    labsales: "",
  },

  getters: {
    labtests: (state) => state.labtests,
    labtest: (state) => state.labtest,
    labdashdata: (state) => state.labdashdata,
    labsales: (state) => state.labsales,
  },

  mutations: {
    setlabtests: (state, labtests) => (state.labtests = labtests),
    setlabtest: (state, labtest) => (state.labtest = labtest),
    setlabdashdata: (state, labdashdata) => (state.labdashdata = labdashdata),
    setlabsales: (state, labsales) => (state.labsales = labsales),
  },

  actions: {
    async get_hospital_lab_dash_details({ commit }, { data, page }) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setpageloading", "get_hospital_lab_dash_details");
      return new Promise((resolve, reject) => {
        axios
          .post("/admin/laboratory/dash", data)
          .then((response) => {
            commit("setpageloading", false);
            if (response.data) {
              commit("setlabdashdata", response.data.data);
            }
            resolve(response);
          })
          .catch((error) => {
            commit("setpageloading", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  comp_play_error_file(error.response.data.msg);
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                  comp_play_error_file(error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },

    async get_hospital_lab_sales({ commit }, { data, page }) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setpageloading", "get_hospital_lab_sales");
      return new Promise((resolve, reject) => {
        axios
          .post("/admin/laboratory/sales?page=" + page, data)
          .then((response) => {
            commit("setpageloading", false);
            if (response.data) {
              commit("setlabsales", response.data.data);
            }
            resolve(response);
          })
          .catch((error) => {
            commit("setpageloading", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  comp_play_error_file(error.response.data.msg);
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                  comp_play_error_file(error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },

    async create_labtest({ commit }, data) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "create_labtest");
      return new Promise((resolve, reject) => {
        axios
          .post("/settings/tests/create", data)
          .then((response) => {
            commit("setloader", false);
            if (response.data) {
              commit("setlabtests", response.data.data);
              toast.success("labtest Added Successfully");
            }
            resolve(response);
          })
          .catch(async (error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  comp_play_error_file(error.response.data.msg);
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                  comp_play_error_file(error.response.data.message);
                }
              }
            }

            reject(error);
          });
      });
    },

    async get_labtests({ commit }, { data, page }) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "get_labtests");
      return new Promise((resolve, reject) => {
        axios
          .post("/settings/tests?page=" + page, data)
          .then((response) => {
            commit("setloader", false);
            if (response.data) {
              commit("setlabtests", response.data.data);
            }
            resolve(response);
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  toast.error(error.response.data.msg);
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },

    async get_labtest({ commit }, { data, id }) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "get_labtest" + id);
      return new Promise((resolve, reject) => {
        axios
          .post("/admin/settings/tests/" + id, data)
          .then((response) => {
            commit("setloader", false);
            if (response.data) {
              commit("setlabtest", response.data.res);
            }
            resolve(response);
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  toast.error(error.response.data.msg);
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },

    async update_labtest({ commit }, { data, id }) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "update_labtest" + id);
      return new Promise((resolve, reject) => {
        axios
          .post("/settings/tests/update/" + id, data)
          .then((response) => {
            commit("setloader", false);
            if (response.data) {
              commit("setlabtests", response.data.data);
              toast.success("labtest Updated Successfully");
            }
            resolve(response);
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  comp_play_error_file(error.response.data.msg);
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                  comp_play_error_file(error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },

    async change_labtest_status({ commit }, { data, id }) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "change_labtest_status" + id);
      return new Promise((resolve, reject) => {
        axios
          .post(`/settings/tests/status/${id}`, data)
          .then((response) => {
            commit("setloader", false);
            if (response.data) {
              commit("setlabtests", response.data.data);
              if (data.status == 1) {
                toast.success("labtest Activated Successfully");
              } else {
                toast.success("labtest Suspended Successfully");
              }
            }
            resolve(response);
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  comp_play_error_file(error.response.data.msg);
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                  comp_play_error_file(error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },

    async delete_labtest({ commit }, { data, id }) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "delete_labtest" + id);
      return new Promise((resolve, reject) => {
        axios
          .post("/settings/tests/delete/" + id, data)
          .then((response) => {
            commit("setloader", false);
            if (response.data) {
              commit("setlabtests", response.data.data);
              comp_play_delete_file("labtest Deleted Successfully");
            }
            resolve(response);
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  comp_play_error_file(error.response.data.msg);
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                  comp_play_error_file(error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },
  },
};
