
export const namespaced = true 
import { comp_play_error_file, comp_play_success_file } from './utils';
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        pharmacydash :'', 
        drugsales :'',
    },
    
    getters : {
        pharmacydash: (state )=> state.pharmacydash,
        drugsales: (state )=> state.drugsales,
    },


    mutations : { 
        setpharmacydash :(state,pharmacydash ) => (state.pharmacydash = pharmacydash), 
        setdrugsales :(state,drugsales ) => (state.drugsales = drugsales), 
    },

    actions : {

        async get_hospital_pharmacy_dash_details( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_hospital_details')
            return new Promise((resolve, reject) => {
            axios.post("/admin/pharmacy/dash", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setpharmacydash', response.data.data)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        } 
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_hospital_pharmacy_sales( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_hospital_pharmacy_sales')
            return new Promise((resolve, reject) => {
            axios.post("/admin/pharmacy/sales", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setdrugsales', response.data.data)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        } 
                    }
                }
                reject(error)
            });
            });	       
        },


        
    }
}