import { useToast } from 'vue-toastification'
import { comp_play_error_file, comp_play_success_file, comp_play_delete_file} from './utils';
const toast = useToast()

export const namespaced = true

export default {
    state: {
        insurance_groups: '',
        insurance_group: '',
        insurance_members: '',
        insurance_member: '',
    },

    getters: {
        insurance_groups: (state) => state.insurance_groups,
        insurance_group: (state) => state.insurance_group,
        insurance_members: (state) => state.insurance_members,
        insurance_member: (state) => state.insurance_member,
    },

    mutations: {
        set_insurance_groups: (state, insurance_groups) => (state.insurance_groups = insurance_groups),
        set_insurance_group: (state, insurance_group) => (state.insurance_group = insurance_group),
        set_insurance_members: (state, insurance_members) => (state.insurance_members = insurance_members),
        set_insurance_member: (state, insurance_member) => (state.insurance_member = insurance_member),
    },

    actions: {
        // Create new insurance group
        async create_insurance_group({ commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_insurance_group')
            
            return new Promise((resolve, reject) => {
                axios.post("/admin/insurance/create", data)
                    .then(response => {
                        commit('setloader', false)
                        if (response.data) {
                            commit('set_insurance_groups', response.data.data)
                            toast.success("Insurance Group Added Successfully")
                        }
                        resolve(response)
                    })
                    .catch(async error => {
                        commit('setloader', false)
                        if (error.response) {
                            if (error.response.data) {
                                if (error.response.data.msg) {
                                    commit('seterror', error.response.data.msg)
                                    await comp_play_error_file(error.response.data.msg)
                                } else if (error.response.data.message) {
                                    commit('seterrors', error.response.data.message)
                                    await comp_play_error_file(error.response.data.message)
                                }
                            }
                        }
                        reject(error)
                    })
            })
        },

        // Get all insurance groups
        async get_insurance_groups({ commit }, { data, page }) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_insurance_groups')
            
            return new Promise((resolve, reject) => {
                axios.post("/admin/insurance?page=" + page, data)
                    .then(response => {
                        commit('setloader', false)
                        if (response.data) {
                            commit('set_insurance_groups', response.data.data)
                        }
                        resolve(response)
                    })
                    .catch(error => {
                        commit('setloader', false)
                        if (error.response?.data?.msg) {
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }
                        reject(error)
                    })
            })
        },

        // Get single insurance group
        async get_insurance_group({ commit }, { data, id }) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_insurance_group')
            
            return new Promise((resolve, reject) => {
                axios.post("/admin/insurance/" + id, data)
                    .then(response => {
                        commit('setloader', false)
                        if (response.data) {
                            commit('set_insurance_group', response.data.data)
                        }
                        resolve(response)
                    })
                    .catch(error => {
                        commit('setloader', false)
                        if (error.response?.data?.msg) {
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }
                        reject(error)
                    })
            })
        },

        // Update insurance group
        async update_insurance_group({ commit }, { data, id }) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_insurance_group' + id)
            
            return new Promise((resolve, reject) => {
                axios.post("/admin/insurance/update/" + id, data)
                    .then(response => {
                        commit('setloader', false)
                        if (response.data) {
                            commit('set_insurance_groups', response.data.data)
                            toast.success("Insurance Group Updated Successfully")
                        }
                        resolve(response)
                    })
                    .catch(async error => {
                        commit('setloader', false)
                        if (error.response?.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                await comp_play_error_file(error.response.data.msg)
                            }
                        }
                        reject(error)
                    })
            })
        },

        // Change insurance group status
        async change_insurance_group_status({ commit }, { data, id }) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_insurance_group_status' + id)
            
            return new Promise((resolve, reject) => {
                axios.post(`/admin/insurance/status/${id}`, data)
                    .then(response => {
                        commit('setloader', false)
                        if (response.data) {
                            commit('set_insurance_groups', response.data.data)
                            toast.success(data.status == 1 ? 
                                "Insurance Group Activated Successfully" : 
                                "Insurance Group Deactivated Successfully")
                        }
                        resolve(response)
                    })
                    .catch(async error => {
                        commit('setloader', false)
                        if (error.response?.data?.msg) {
                            commit('seterror', error.response.data.msg)
                            await comp_play_error_file(error.response.data.msg)
                        }
                        reject(error)
                    })
            })
        },

        // Delete insurance group
        async delete_insurance_group({ commit }, { data, id }) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_insurance_group' + id)
            
            return new Promise((resolve, reject) => {
                axios.post("/admin/insurance/delete/" + id, data)
                    .then(response => {
                        commit('setloader', false)
                        if (response.data) {
                            commit('set_insurance_group', response.data.data)
                            comp_play_delete_file('Insurance Group Deleted Successfully')
                        }
                        resolve(response)
                    })
                    .catch(async error => {
                        commit('setloader', false)
                        if (error.response?.data?.msg) {
                            commit('seterror', error.response.data.msg)
                            await comp_play_error_file(error.response.data.msg)
                        }
                        reject(error)
                    })
            })
        },

        // Create insurance member
        async create_insurance_member({ commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_insurance_member')
            
            return new Promise((resolve, reject) => {
                axios.post("/admin/insurance/members/create", data)
                    .then(response => {
                        commit('setloader', false)
                        if (response.data) {
                            commit('set_insurance_group', response.data.data)
                            toast.success("Insurance Member Added Successfully")
                        }
                        resolve(response)
                    })
                    .catch(async error => {
                        commit('setloader', false)
                        if (error.response?.data?.msg) {
                            commit('seterror', error.response.data.msg)
                            await comp_play_error_file(error.response.data.msg)
                        }
                        reject(error)
                    })
            })
        },

        // Get insurance members
        async change_insurance_member_status({ commit }, {data, id}){
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_insurance_member_status')
            return new Promise((resolve, reject) => {
                axios.post("/admin/insurance/members/status/" + id, data)
                    .then(response => {
                        commit('setloader', false)
                        if (response.data) {
                            commit('set_insurance_group', response.data.data)
                            commit('setmsg', response.data.msg)
                            toast.success(response.data.msg)
                            resolve(response)
                        }
                    })
                    .catch(error => {
                        commit('setloader', false)
                        if (error.response?.data?.msg) {
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }
                        reject(error)
                    })
            })
        },

        async delete_insurance_member({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_insurance_member')
            
            return new Promise((resolve, reject) => {
                axios.post("/admin/insurance/members/delete/" + id, data)
                    .then(response => {
                        commit('setloader', false)
                        if (response.data) {
                            commit('set_insurance_group', response.data.data)
                            commit('setmsg', response.data.msg)
                            toast.success(response.data.msg)
                            resolve(response)
                        }
                    })
                    .catch(error => {
                        commit('setloader', false)
                        if (error.response?.data?.msg) {
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }
                        reject(error)
                    })
            })
        }
    }
}