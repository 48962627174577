export const namespaced = true 
import { comp_play_error_file, comp_play_success_file, comp_play_delete_file} from './utils';
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        services :'', 
        service :'', 
        servicedash :'',
        servicesales :'',
    },
    
    getters : {
        services: (state )=> state.services,
        service: (state )=> state.service,
        servicesales: (state )=> state.servicesales,
        servicedash: (state )=> state.servicedash,
    },


    mutations : { 
        setservices :(state,services ) => (state.services = services), 
        setservice :(state,service ) => (state.service = service), 
        setservicesales :(state,servicesales ) => (state.servicesales = servicesales), 
        setservicedash :(state,servicedash ) => (state.servicedash = servicedash), 
    },

    actions : {

        async get_hospital_service_dash_details( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setpageloading', 'get_hospital_service_dash_details')
            return new Promise((resolve, reject) => {
            axios.post("/admin/services/dash", data)
            .then(response => {	
                commit('setpageloading', false)
                if(response.data){
                commit('setservicedash', response.data.data)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setpageloading', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        } 
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_hospital_service_sales( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setpageloading', 'get_hospital_service_sales')
            return new Promise((resolve, reject) => {
            axios.post("/admin/services/sales?page=" +page, data)
            .then(response => {	
                commit('setpageloading', false)
                if(response.data){
                commit('setservicesales', response.data.data)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setpageloading', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        } 
                    }
                }
                reject(error)
            });
            });	       
        },


        async create_service( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_service')
            return new Promise((resolve, reject) => {
            axios.post("/settings/services/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setservices', response.data.data)
                toast.success("service Added Successfully")
                }
                resolve(response)
                
            })
            .catch(async error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        }
                        
                    }
                }
                
                reject(error)
            });
            });	       
        },


        async get_services( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_services')
            return new Promise((resolve, reject) => {
            axios.post("/settings/services?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setservices', response.data.data)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_service( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_service' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/settings/services/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setservice', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_service( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_service' + id)
            return new Promise((resolve, reject) => {
            axios.post("/settings/services/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setservices', response.data.data)
                toast.success("service Updated Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async change_service_status({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_service_status' + id)
            return new Promise((resolve, reject) => {
                axios.post(`/settings/services/status/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setservices', response.data.data)
                        if (data.status == 1) {
                            toast.success("service Activated Successfully")
                        } else {
                            toast.success("service Suspended Successfully")
                        }
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if(error.response.data.msg){
                                commit('seterror', error.response.data.msg)
                                comp_play_error_file(error.response.data.msg);
                            }else if(error.response.data.message){
                                commit('seterrors', error.response.data.message)
                                comp_play_error_file(error.response.data.message);
                            }
                        }
                    }
                    reject(error)
                });
            });
        },

        async delete_service( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_service' + id)
            return new Promise((resolve, reject) => {
            axios.post("/settings/services/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setservices', response.data.data)
                comp_play_delete_file('service Deleted Successfully');
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


 
    }


    
}