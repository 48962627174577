export const namespaced = true
import { useToast } from 'vue-toastification'
import { comp_play_error_file, comp_play_success_file } from './utils';
const toast = useToast()

export default {
    state: {
        ambulancedash: '',
        ambulances: '',
        ambulance: '',
        ambulancerequests: '',
    },

    getters: {
        ambulancedash: (state) => state.ambulancedash,
        ambulances: (state) => state.ambulances,
        ambulance: (state) => state.ambulance,
        ambulancerequests: (state) => state.ambulancerequests,

    },

    mutations: {
        setambulancedash: (state, ambulancedash) => (state.ambulancedash = ambulancedash),
        setambulances: (state, ambulances) => (state.ambulances = ambulances),
        setambulance: (state, ambulance) => (state.ambulance = ambulance),
        setambulancerequests: (state, ambulancerequests) => (state.ambulancerequests = ambulancerequests),
 
    },

    actions: {
        // Dashboard Data
        async get_ambulance_dash_data({ commit }) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_ambulance_dash_data')
            
            return new Promise((resolve, reject) => {
                axios.post("/admin/ambulances/dashboard")
                    .then(response => {
                        commit('setloader', false)
                        if (response.data) {
                            commit('setambulancedash', response.data.data)
                        }
                        resolve(response)
                    })
                    .catch(async error => {
                        commit('setloader', false)
                        if (error.response?.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                await comp_play_error_file(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                                await comp_play_error_file(error.response.data.message)
                            }
                        }
                        reject(error)
                    })
            })
        },

        // Ambulance Management
        async get_all_ambulances({ commit }, { data, page }) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_all_ambulances')
            
            return new Promise((resolve, reject) => {
                axios.post("/admin/ambulances/list?page=" + page, data)
                    .then(response => {
                        commit('setloader', false)
                        if (response.data) {
                            commit('setambulances', response.data.data)
                        }
                        resolve(response)
                    })
                    .catch(async error => {
                        commit('setloader', false)
                        if (error.response?.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                await comp_play_error_file(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                                await comp_play_error_file(error.response.data.message)
                            }
                        }
                        reject(error)
                    })
            })
        },

        async get_ambulance_details({ commit }, id) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_ambulance_details')
            
            return new Promise((resolve, reject) => {
                axios.post(`/admin/ambulances/${id}`)
                    .then(response => {
                        commit('setloader', false)
                        if (response.data) {
                            commit('setambulance', response.data.data)
                        }
                        resolve(response)
                    })
                    .catch(async error => {
                        commit('setloader', false)
                        if (error.response?.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                await comp_play_error_file(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                                await comp_play_error_file(error.response.data.message)
                            }
                        }
                        reject(error)
                    })
            })
        },


        //create ambulance
        async create_new_ambulance({ commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_new_ambulance')
            
            return new Promise((resolve, reject) => {
                axios.post("/admin/ambulances/create", data)
                    .then(response => {
                        commit('setloader', false)
                        if (response.data) {
                            commit('setambulances', response.data.data)
                            toast.success("Ambulance created successfully")
                        }
                        resolve(response)
                    })
                    .catch(async error => {
                        commit('setloader', false)
                        if (error.response?.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                await comp_play_error_file(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                                await comp_play_error_file(error.response.data.message)
                            }
                        }
                        reject(error)
                    })
            })
        },

        async update_ambulance({ commit }, { data, id }) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_ambulance')
            
            return new Promise((resolve, reject) => {
                axios.post(`/admin/ambulances/update/${id}`, data)
                    .then(response => {
                        commit('setloader', false)
                        if (response.data) {
                            commit('setambulances', response.data.data)
                            toast.success("Ambulance updated successfully")
                        }
                        resolve(response)
                    })
                    .catch(async error => {
                        commit('setloader', false)
                        if (error.response?.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                await comp_play_error_file(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                                await comp_play_error_file(error.response.data.message)
                            }
                        }
                        reject(error)
                    })
            })
        },

        async get_ambulance_details({ commit }, { data, id }) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_ambulance_details')
            
            return new Promise((resolve, reject) => {
                axios.post(`/admin/ambulances/${id}`, data)
                    .then(response => {
                        commit('setloader', false)
                        if (response.data) {
                            commit('setambulance', response.data.data)
                            toast.success("Ambulance status updated successfully")
                        }
                        resolve(response)
                    })
                    .catch(async error => {
                        commit('setloader', false)
                        if (error.response?.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                await comp_play_error_file(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                                await comp_play_error_file(error.response.data.message)
                            }
                        }
                        reject(error)
                    })
            })
        },

        async change_ambulance_status({ commit }, { data, id }) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_ambulance_status')
            
            return new Promise((resolve, reject) => {
                axios.post(`/admin/ambulances/status/${id}`, data)
                    .then(response => {
                        commit('setloader', false)
                        if (response.data) {
                            commit('setambulances', response.data.data)
                            toast.success("Ambulance status updated successfully")
                        }
                        resolve(response)
                    })
                    .catch(async error => {
                        commit('setloader', false)
                        if (error.response?.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                await comp_play_error_file(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                                await comp_play_error_file(error.response.data.message)
                            }
                        }
                        reject(error)
                    })
            })
        },

        async delete_ambulance({ commit }, { data, id }) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_ambulance')
            
            return new Promise((resolve, reject) => {
                axios.post(`/admin/ambulances/delete/${id}`, data)
                    .then(response => {
                        commit('setloader', false)
                        if (response.data) {
                            commit('setambulances', response.data.data)
                            toast.success("Ambulance deleted successfully")
                        }
                        resolve(response)
                    })
                    .catch(async error => {
                        commit('setloader', false)
                        if (error.response?.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                await comp_play_error_file(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                                await comp_play_error_file(error.response.data.message)
                            }
                        }
                        reject(error)
                    })
            })
        },

        // Ambulance Requests
        async get_ambulance_requests({ commit }, { data, page }) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_ambulance_requests')
            
            return new Promise((resolve, reject) => {
                axios.post("/admin/ambulances/requests/list?page=" + page, data)
                    .then(response => {
                        commit('setloader', false)
                        if (response.data) {
                            commit('setambulancerequests', response.data.data)
                        }
                        resolve(response)
                    })
                    .catch(async error => {
                        commit('setloader', false)
                        if (error.response?.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                await comp_play_error_file(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                                await comp_play_error_file(error.response.data.message)
                            }
                        }
                        reject(error)
                    })
            })
        },

        async create_new_request({ commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_new_request')
            
            return new Promise((resolve, reject) => {
                axios.post("/admin/ambulances/requests/create", data)
                    .then(response => {
                        commit('setloader', false)
                        if (response.data) {
                            commit('setambulancerequests', response.data.data)
                            toast.success("Ambulance request created successfully")
                        }
                        resolve(response)
                    })
                    .catch(async error => {
                        commit('setloader', false)
                        if (error.response?.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                await comp_play_error_file(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                                await comp_play_error_file(error.response.data.message)
                            }
                        }
                        reject(error)
                    })
            })
        },

        async update_request_status({ commit }, { data, id }) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_request_status')
            
            return new Promise((resolve, reject) => {
                axios.post(`/admin/ambulances/requests/status/${id}`, data)
                    .then(response => {
                        commit('setloader', false)
                        if (response.data) {
                            commit('setambulancerequests', response.data.data)
                            toast.success("Request status updated successfully")
                        }
                        resolve(response)
                    })
                    .catch(async error => {
                        commit('setloader', false)
                        if (error.response?.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                await comp_play_error_file(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                                await comp_play_error_file(error.response.data.message)
                            }
                        }
                        reject(error)
                    })
            })
        }
    }
}