import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import SecureLs from 'secure-ls'
const secure = new SecureLs({isCompression:false});
import auth  from './modules/auth'
import utils  from './modules/utils'
import systemupdates from './modules/systemupdates';
import systemsettings from './modules/systemsettings';

//dashboards
import userdashboard from './modules/userdashboard';
import patients from './modules/patients';
import patient_cases from './modules/patient_cases';
//humanresource
import employees from './modules/employees';

//drugs
import drug_stocks from './modules/drug_stocks';
import drug_classes from './modules/drug_classes';
import drug_groups from './modules/drug_groups';
import drug_categories from './modules/drug_categories';
import drugs from './modules/drugs';

//pos
import user_pos from './modules/user_pos';

import finances from './modules/finances';
import requsitions from './modules/requsitions';
//settings
import roles from './modules/roles';
import permissions from './modules/permissions';
import hospital from './modules/hospital';
import rooms from './modules/rooms';
import departments from './modules/departments';
import hospital_services from './modules/hospital_services';
import labtests from './modules/labtests';
import patient_classes from './modules/patient_classes';
import inventory_items from './modules/inventory_items';
import trashbin from './modules/trashbin';
import insurance from './modules/insurance';
import ambulances from './modules/ambulances';
//admin
import pharmacy from './modules/pharmacy';

export default createStore({
  modules: {
    auth, 
    utils,
    systemupdates,
    systemsettings,

    finances,
    requsitions,

  
    userdashboard,
    patients,
    patient_cases,

    employees,

    //drugs
    drug_stocks,
    drug_classes,
    drug_groups,
    drug_categories,
    drugs,
    user_pos,
    
    roles,
    permissions,
    hospital,
    rooms,
    departments,
    hospital_services,
    labtests,
    patient_classes,
    inventory_items,
    trashbin,

    //admin
    pharmacy,
    insurance,
    ambulances
  },
    
  plugins: [createPersistedState(
    {
      key : 'hms.deepcodegroup.com',
      paths: ['auth','utils'],
      storage:{
         getItem : (key) => secure.get(key),
         setItem: (key, value) => secure.set(key, value),
         removeItem: (key) => secure.remove(key)
      }
    }
  )],
  
})
