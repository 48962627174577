<template>
    <!-- Main Drawer Container with Backdrop -->
    <div 
      class="fixed inset-0 z-50 overflow-hidden"
      v-if="isOpen"
      v-cloak
    >
      <!-- Enhanced Backdrop with Gradient -->
      <div 
        class="absolute inset-0 bg-gradient-to-br from-slate-900/70 via-green-900/60 to-slate-900/70 
               backdrop-blur-sm transition-all duration-500 ease-out"
        @click="closeAllForms"
      ></div>
      
      <!-- Enhanced Drawer -->
      <div 
        class="absolute inset-y-0 right-0 w-[60vw] transform transition-all duration-500 ease-out"
        :class="[isOpen ? 'translate-x-0' : 'translate-x-full']"
      >
        <div class="h-full flex flex-col bg-white/95 backdrop-blur-sm shadow-2xl">
          <!-- Enhanced Header -->
          <div class="px-8 py-6 bg-gradient-to-br from-white via-slate-50/50 to-green-50/30">
            <div class="flex items-center justify-between">
              <div class="group cursor-default">
                <h2 class="text-2xl font-bold bg-gradient-to-r from-slate-800 to-green-700 bg-clip-text text-transparent
                           group-hover:from-green-600 group-hover:to-indigo-600 transition-all duration-300">
                  Prescribe Drugs
                </h2>
                <p class="mt-1.5 text-slate-600 text-sm">
                  Managing prescriptions and medications
                </p>
              </div>
              
              <!-- Enhanced Close Button -->
              <button 
                @click="closeAllForms"
                class="p-2.5 rounded-full text-slate-500 hover:text-slate-700
                       hover:bg-slate-100/80 active:bg-slate-200/80
                       transition-all duration-200 group"
              >
                <svg 
                  class="w-6 h-6 transform transition-transform duration-200 group-hover:rotate-90" 
                  fill="none" 
                  viewBox="0 0 24 24" 
                  stroke="currentColor"
                >
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
  
            <!-- Enhanced Search with Animation -->
            <div class="mt-6">
              <div class="group relative">
                <div class="absolute inset-y-0 left-4 flex items-center pointer-events-none
                           text-slate-400 group-focus-within:text-green-500 transition-colors duration-300">
                  <svg class="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                  </svg>
                </div>
                <input 
                  v-model="searchDrugs"
                  @input="getDrugsPageData(currentPage)"
                  type="text"
                  placeholder="Search medications..."
                  class="w-full pl-12 pr-4 py-3.5 bg-white/70 border border-slate-200 rounded-xl
                         placeholder:text-slate-400 focus:outline-none focus:ring-2
                         focus:ring-green-500 focus:border-transparent
                         hover:border-slate-300 focus:bg-white
                         transition-all duration-200"
                />
              </div>
  
              <!-- Enhanced Filters -->
              <div class="grid grid-cols-3 gap-4 mt-4">
                <div class="group relative">
                  <select 
                    v-model="formInput.druggroup_id"
                    @change="getDrugsPageData(currentPage)"
                    class="w-full appearance-none pl-4 pr-10 py-3 bg-white/70 border border-slate-200 rounded-xl
                           text-slate-600 focus:outline-none focus:ring-2
                           focus:ring-green-500 focus:border-transparent
                           hover:border-slate-300 focus:bg-white
                           transition-all duration-200"
                  >
                    <option value="">All Groups</option>
                    <option v-for="group in drugGroups.data" :key="group.id" :value="group.id">
                      {{ group.title }}
                    </option>
                  </select>
                  <div class="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none
                             text-slate-400 group-focus-within:text-green-500">
                    <svg class="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                    </svg>
                  </div>
                </div>
  
                <!-- Similar enhanced styling for other select elements -->
                <!-- Categories Select -->
                <div class="group relative">
                  <select 
                    v-model="formInput.drugcategory_id"
                    @change="getDrugsPageData(currentPage)"
                    class="w-full appearance-none pl-4 pr-10 py-3 bg-white/70 border border-slate-200 rounded-xl
                           text-slate-600 focus:outline-none focus:ring-2
                           focus:ring-green-500 focus:border-transparent
                           hover:border-slate-300 focus:bg-white
                           transition-all duration-200"
                  >
                    <option value="">All Categories</option>
                    <option v-for="category in drugCategories.data" :key="category.id" :value="category.id">
                      {{ category.title }}
                    </option>
                  </select>
                  <div class="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none
                             text-slate-400 group-focus-within:text-green-500">
                    <svg class="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                    </svg>
                  </div>
                </div>
  
                <!-- Classes Select -->
                <div class="group relative">
                  <select 
                    v-model="formInput.drugclass_id"
                    @change="getDrugsPageData(currentPage)"
                    class="w-full appearance-none pl-4 pr-10 py-3 bg-white/70 border border-slate-200 rounded-xl
                           text-slate-600 focus:outline-none focus:ring-2
                           focus:ring-green-500 focus:border-transparent
                           hover:border-slate-300 focus:bg-white
                           transition-all duration-200"
                  >
                    <option value="">All Classes</option>
                    <option v-for="dclass in drugClasses.data" :key="dclass.id" :value="dclass.id">
                      {{ dclass.title }}
                    </option>
                  </select>
                  <div class="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none
                             text-slate-400 group-focus-within:text-green-500">
                    <svg class="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <!-- Enhanced Drug Cards Grid -->
          <div class="flex-1 overflow-y-auto px-8 py-6 bg-gradient-to-br from-white to-slate-50/30">
            <!-- Loading State -->
            <div v-if="loading === 'get_drugs'" class="flex flex-col items-center justify-center h-full">
              <div class="relative">
                <div class="w-16 h-16 border-4 border-green-200 rounded-full animate-pulse"></div>
                <div class="absolute inset-0 w-16 h-16 border-4 border-green-500 rounded-full border-t-transparent animate-spin"></div>
              </div>
              <p class="mt-4 text-slate-600 animate-pulse">Loading medications...</p>
            </div>
  
            <!-- Drug Cards Grid -->
            <div v-else class="grid grid-cols-4 gap-5">
              <div 
                v-for="drug in drugs.data" 
                :key="drug.id"
                @click="showDrugPrescriptionModal(drug)"
                class="group relative bg-white rounded-xl border-2 p-4 cursor-pointer
                       hover:shadow-lg hover:shadow-green-500/5 
                       transform hover:-translate-y-0.5
                       transition-all duration-300"
                :class="{
                  'border-red-200 bg-red-50/50': isOutOfStock(drug),
                  'border-amber-200 bg-amber-50/50': isLowStock(drug),
                  'border-emerald-200 bg-emerald-50/50': isWellStocked(drug)
                }"
              >
                <!-- Stock Status Badge -->
                <div 
                  class="absolute top-3 right-3 px-2.5 py-1 rounded-full text-xs font-semibold
                         shadow-sm transition-all duration-200"
                  :class="{
                    'bg-red-100 text-red-700': isOutOfStock(drug),
                    'bg-amber-100 text-amber-700': isLowStock(drug),
                    'bg-emerald-100 text-emerald-700': isWellStocked(drug)
                  }"
                >
                  {{ getStockStatus(drug) }}
                </div>
  
                <!-- Drug Info -->
                <div class="mt-6">
                  <h3 class="font-semibold text-slate-900 group-hover:text-green-600 transition-colors duration-200">
                    {{ drug.title }}
                  </h3>
                  <div class="mt-3 space-y-2">
                    <p class="text-sm text-slate-600">
                      <span class="font-medium">Group:</span> {{ drug.druggroup?.title }}
                    </p>
                    <p class="text-sm text-slate-600">
                      <span class="font-medium">Category:</span> {{ drug.drugcategory?.title }}
                    </p>
                    <p class="text-sm text-slate-600">
                      <span class="font-medium">Available:</span> 
                      {{ (drug.drug_available_stock ?? 0) - (drug.drug_used_stock ?? 0) }} {{ drug.unit }}
                    </p>
                  </div>
  
                  <!-- Action Button -->
                  <button 
                    class="mt-4 w-full px-4 py-2.5 rounded-lg text-sm font-medium
                           bg-gradient-to-r from-green-500 to-green-600 text-white
                           opacity-0 group-hover:opacity-100 
                           transform translate-y-2 group-hover:translate-y-0
                           transition-all duration-200
                           hover:from-green-600 hover:to-green-700
                           focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                  >
                    Prescribe Now
                  </button>
                </div>
              </div>
            </div>
          </div>
  
          <!-- Selected Medications Section -->
          <div class="border-t border-slate-200">
            <div class="p-6 bg-gradient-to-br from-slate-50 to-white">
              <div class="flex items-center justify-between mb-4">
                <h6 class="font-semibold text-slate-800">Selected Medications</h6>
                <span class="px-2.5 py-1 bg-green-50 text-green-600 rounded-lg text-sm font-medium">
                  {{ livePrescriptionList.length }} selected
                </span>
              </div>
  
              <!-- Selected Items List -->
              <div class="space-y-3">
                <div 
                  v-for="(pres, index) in livePrescriptionList" 
                  :key="index"
                  class="group bg-white rounded-xl border border-slate-200 p-4
                         hover:border-green-200 hover:shadow-md hover:shadow-green-500/5
                         transform hover:-translate-y-0.5
                         transition-all duration-200"
                >
                  <div class="flex items-center justify-between">
                    <div>
                      <h4 class="font-medium text-slate-900">{{ pres.drug.title }}</h4>
                      <div class="mt-2 flex items-center gap-3">
                        <span class="px-3 py-1 bg-green-50 text-green-600 rounded-lg text-sm">
                          {{ pres.dosing_freg }}
                        </span>
                        <span class="px-3 py-1 bg-emerald-50 text-emerald-600 rounded-lg text-sm">
                          {{ pres.dose_quantity }} {{ pres.drug.unit }}
                        </span>
                      </div>
                    </div>
                    <button 
                      @click="removePrescriptionFromList(index)"
                      class="p-2 rounded-full text-slate-400 opacity-0 group-hover:opacity-100
                             hover:bg-red-50 hover:text-red-500
                             transform translate-x-2 group-hover:translate-x-0
                             transition-all duration-200"
                    >
                      <svg class="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
  
            <!-- Footer Actions -->
            <div class="p-6 bg-white border-t border-slate-200">
              <div class="flex items-center justify-between">
                <button 
                  @click="clearPrescriptionList"
                  class="px-4 py-2 text-slate-600 hover:text-slate-800
                         hover:bg-slate-50 rounded-lg
                         transition-all duration-200"
                  :disabled="!livePrescriptionList.length"
                >
                  Clear All
                </button>
                <button 
                @click="compPrescribedrugPatientCase"
                :disabled="loading === 'prescribe_drug_patient_case' || !livePrescriptionList.length"
                class="px-6 py-2.5 bg-gradient-to-r from-green-500 to-green-600
                       text-white rounded-xl font-medium
                       hover:from-green-600 hover:to-green-700
                       disabled:from-slate-300 disabled:to-slate-400
                       disabled:cursor-not-allowed
                       transform hover:scale-[1.02] active:scale-[0.98]
                       shadow-sm hover:shadow-md hover:shadow-green-500/10
                       transition-all duration-200"
              >
                <div class="flex items-center gap-2">
                  <svg 
                    v-if="loading === 'prescribe_drug_patient_case'"
                    class="animate-spin h-5 w-5" 
                    fill="none" 
                    viewBox="0 0 24 24"
                  >
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4" />
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                  </svg>
                  <span class="inline-block">
                    {{ loading === 'prescribe_drug_patient_case' ? 'Prescribing...' : 'Complete Prescription' }}
                  </span>
                  <svg 
                    v-if="!loading"
                    class="w-5 h-5 transition-transform duration-200 group-hover:translate-x-1" 
                    fill="none" 
                    viewBox="0 0 24 24" 
                    stroke="currentColor"
                  >
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                  </svg>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Enhanced Prescription Modal -->
  <div 
    v-if="showPrescriptionModal"
    class="fixed inset-0 z-[60] overflow-y-auto"
    @click.self="closePrescriptionModal"
  >
    <div class="min-h-screen px-4 text-center flex items-center justify-center">
      <!-- Enhanced Backdrop -->
      <div class="fixed inset-0 bg-gradient-to-br from-slate-900/70 via-green-900/60 to-slate-900/70 backdrop-blur-sm transition-opacity"></div>

      <!-- Modal Content -->
      <div 
        class="relative inline-block w-full max-w-md p-8 text-left bg-white/95 backdrop-blur-sm
               shadow-xl rounded-2xl transform transition-all duration-300
               scale-in-modal"
      >
        <!-- Modal Header -->
        <h3 class="text-xl font-semibold text-slate-900 mb-6">
          Prescribe {{ drugDetails.title }}
        </h3>

        <form @submit.prevent="addDrugToPrescriptionList(drugDetails)" class="space-y-6">
          <!-- Quantity Input -->
          <div>
            <label class="block text-sm font-medium text-slate-700 mb-2">
              Number of {{ drugDetails.unit }}
            </label>
            <div class="relative">
              <input 
                v-model="formInput.dose_quantity"
                type="number"
                class="w-full rounded-xl border border-slate-200 px-4 py-3
                       focus:ring-2 focus:ring-green-500 focus:border-transparent
                       hover:border-slate-300
                       placeholder:text-slate-400
                       transition-all duration-200"
                placeholder="Enter quantity"
              />
              <div 
                v-if="formError.dose_quantity"
                class="absolute -bottom-5 left-0 text-sm text-red-500 flex items-center gap-1"
              >
                <svg class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                        d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                </svg>
                <span>{{ formError.dose_quantity }}</span>
              </div>
            </div>
          </div>

          <!-- Frequency Input -->
          <div>
            <label class="block text-sm font-medium text-slate-700 mb-2">
              Dosing Frequency
            </label>
            <div class="relative">
              <input 
                v-model="formInput.dosing_freg"
                type="text"
                class="w-full rounded-xl border border-slate-200 px-4 py-3
                       focus:ring-2 focus:ring-green-500 focus:border-transparent
                       hover:border-slate-300
                       placeholder:text-slate-400
                       transition-all duration-200"
                placeholder="e.g. 3 times daily"
              />
              <div 
                v-if="formError.dosing_freg"
                class="absolute -bottom-5 left-0 text-sm text-red-500 flex items-center gap-1"
              >
                <svg class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                        d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                </svg>
                <span>{{ formError.dosing_freg }}</span>
              </div>
            </div>
          </div>

          <!-- Stock Information -->
          <div class="p-4 bg-gradient-to-r from-green-50 to-slate-50 rounded-xl">
            <div class="flex justify-between items-center">
              <span class="text-sm text-slate-600">Available Stock:</span>
              <span class="text-sm font-medium text-green-600">
                {{ (drugDetails.drug_available_stock ?? 0) - (drugDetails.drug_used_stock ?? 0) }} {{ drugDetails.unit }}
              </span>
            </div>
          </div>

          <!-- Action Buttons -->
          <div class="flex justify-end gap-3 mt-8">
            <button 
              type="button"
              @click="closePrescriptionModal"
              class="px-4 py-2.5 border border-slate-200 text-slate-600
                     rounded-xl hover:bg-slate-50 hover:text-slate-800
                     focus:ring-2 focus:ring-slate-500 focus:ring-offset-2
                     transition-all duration-200"
            >
              Cancel
            </button>
            <button 
              type="submit"
              class="px-4 py-2.5 bg-gradient-to-r from-green-500 to-green-600
                     text-white rounded-xl hover:from-green-600 hover:to-green-700
                     focus:ring-2 focus:ring-green-500 focus:ring-offset-2
                     shadow-sm hover:shadow-md hover:shadow-green-500/10
                     transform hover:scale-[1.02] active:scale-[0.98]
                     transition-all duration-200"
            >
              <div class="flex items-center gap-2">
                <span>Add to Prescription</span>
                <svg class="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                </svg>
              </div>
            </button>
          </div>
        </form>

        <!-- Close Button -->
        <button 
          @click="closePrescriptionModal"
          class="absolute top-4 right-4 p-2 text-slate-400 hover:text-slate-600
                 hover:bg-slate-100 rounded-full
                 transition-all duration-200"
        >
          <svg class="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const emit = defineEmits(['closed', 'prescribe-drugs'])

// Props
const props = defineProps({
  patientCaseId: {
    type: String,
    required: true
  }
})

// Reactive state
const isOpen = ref(true)
const showPrescriptionModal = ref(false)
const currentPage = ref(1)
const sortField = ref('')
const sortOrder = ref('desc')
const perPage = ref(8)
const searchDrugs = ref('')
const drugDetails = ref({})
const livePrescriptionList = ref([])
const searchTimeout = ref(null)

const formInput = reactive({
  drugcategory_id: '',
  drugclass_id: '',
  druggroup_id: '',
  dosing_freg: '',
  dose_quantity: '',
})

const formError = reactive({
  dosing_freg: '',
  dose_quantity: '',
})

// Computed properties
const loading = computed(() => store.getters.loading)
const clientlocation = computed(() => store.getters.clientlocation)
const drugs = computed(() => store.getters.drugs)
const drugClasses = computed(() => store.getters.drug_classes)
const drugGroups = computed(() => store.getters.drug_groups)
const drugCategories = computed(() => store.getters.drug_categories)

// Watchers
watch(searchDrugs, (newValue) => {
  if (searchTimeout.value) clearTimeout(searchTimeout.value)
  searchTimeout.value = setTimeout(() => {
    getDrugsPageData(1)
  }, 300)
})

// Helper functions
const isOutOfStock = (drug) => {
  return (drug.drug_available_stock ?? 0) - (drug.drug_used_stock ?? 0) === 0
}

const isLowStock = (drug) => {
  const available = (drug.drug_available_stock ?? 0) - (drug.drug_used_stock ?? 0)
  return available > 0 && available < drug.stock_warning_qty
}

const isWellStocked = (drug) => {
  const available = (drug.drug_available_stock ?? 0) - (drug.drug_used_stock ?? 0)
  return available >= drug.stock_warning_qty
}

const getStockStatus = (drug) => {
  if (isOutOfStock(drug)) return 'Out of Stock'
  if (isLowStock(drug)) return 'Low Stock'
  return 'In Stock'
}

const getAvailableStock = (drug) => {
  return (drug.drug_available_stock ?? 0) - (drug.drug_used_stock ?? 0)
}

// Drug list methods
const getDrugsPageData = async (page) => {
  currentPage.value = page
  const data = {
    drugclass: formInput.drugclass_id,
    drugcategory: formInput.drugcategory_id,
    druggroup: formInput.druggroup_id,
    sort_field: sortField.value,
    sort_order: sortOrder.value,
    per_page: perPage.value,
    search: searchDrugs.value,
    accessdata: JSON.stringify(clientlocation.value),
  }
  await store.dispatch('get_drugs', { data, page })
}

const getPageDrugGroups = async () => {
  const data = {
    sort_field: sortField.value,
    sort_order: sortOrder.value,
    per_page: 200,
    search: searchDrugs.value,
    accessdata: JSON.stringify(clientlocation.value),
  }
  await store.dispatch('get_drug_groups', { data, page: 1 })
}

const getPageDrugCategories = async () => {
  const data = {
    sort_field: sortField.value,
    sort_order: sortOrder.value,
    per_page: 200,
    search: searchDrugs.value,
    accessdata: JSON.stringify(clientlocation.value),
  }
  await store.dispatch('get_drug_categories', { data, page: 1 })
}

const getPageDrugClasses = async () => {
  const data = {
    sort_field: sortField.value,
    sort_order: sortOrder.value,
    per_page: 200,
    search: searchDrugs.value,
    accessdata: JSON.stringify(clientlocation.value),
  }
  await store.dispatch('get_drug_classes', { data, page: 1 })
}

// Prescription modal methods
const showDrugPrescriptionModal = (drug) => {
  if (isOutOfStock(drug)) {
    store.dispatch('template_play_error_file', 'This drug is currently out of stock')
    return
  }
  drugDetails.value = drug
  showPrescriptionModal.value = true
  resetFormErrors()
}

const closePrescriptionModal = () => {
  showPrescriptionModal.value = false
  resetFormInputs()
  resetFormErrors()
}

const resetFormInputs = () => {
  formInput.dosing_freg = ''
  formInput.dose_quantity = ''
}

const resetFormErrors = () => {
  formError.dosing_freg = ''
  formError.dose_quantity = ''
}

// Prescription list methods
const addDrugToPrescriptionList = (drug) => {
  if (!validatePrescriptionForm()) return

  if (isDrugAlreadyPrescribed(drug)) {
    store.dispatch('template_play_error_file', 'This drug is already in the prescription list')
    return
  }

  const data = {
    drug,
    dosing_freg: formInput.dosing_freg,
    dose_quantity: formInput.dose_quantity,
  }
  
  livePrescriptionList.value.push(data)
  closePrescriptionModal()
  store.dispatch('template_play_success_file', 'Drug added to prescription list')
}

const validatePrescriptionForm = () => {
  let isValid = true
  resetFormErrors()

  if (!formInput.dose_quantity) {
    formError.dose_quantity = 'Dose quantity is required'
    isValid = false
  } else if (formInput.dose_quantity <= 0) {
    formError.dose_quantity = 'Dose quantity must be greater than 0'
    isValid = false
  }

  if (!formInput.dosing_freg) {
    formError.dosing_freg = 'Dosing frequency is required'
    isValid = false
  }

  if (!isValid) {
    store.dispatch('template_play_error_file', 'Please fill in all required fields correctly')
  }

  return isValid
}

const isDrugAlreadyPrescribed = (drug) => {
  return livePrescriptionList.value.some(item => item.drug.id === drug.id)
}

const removePrescriptionFromList = (index) => {
  livePrescriptionList.value.splice(index, 1)
  store.dispatch('template_play_success_file', 'Drug removed from prescription list')
}

const clearPrescriptionList = () => {
  if (livePrescriptionList.value.length === 0) return
  livePrescriptionList.value = []
  store.dispatch('template_play_success_file', 'Prescription list cleared')
}

// Submit prescription
const compPrescribedrugPatientCase = async () => {
  if (livePrescriptionList.value.length === 0) {
    store.dispatch('template_play_error_file', 'You cannot prescribe an empty list')
    return
  }

  try {
    const data = {
      prescriptions: JSON.stringify(livePrescriptionList.value),
    }
    await store.dispatch('prescribe_drug_patient_case', { data, id: props.patientCaseId })
    livePrescriptionList.value = []
    closeAllForms()
    emit('prescribe-drugs')
  } catch (error) {
    store.dispatch('template_play_error_file', 'Failed to submit prescription. Please try again.')
  }
}

// Modal/drawer control
const showCasePrescribeDrugsModal = async () => {
  await getDrugsPageData(currentPage.value)
  await Promise.all([
    getPageDrugGroups(),
    getPageDrugCategories(),
    getPageDrugClasses()
  ])
}

const closeAllForms = () => {
  isOpen.value = false
  showPrescriptionModal.value = false
  emit('closed')
}

// Sort methods
const setSortField = (field) => {
  if (sortField.value === field) {
    sortOrder.value = sortOrder.value === 'asc' ? 'desc' : 'asc'
  } else {
    sortField.value = field
    sortOrder.value = 'asc'
  }
  getDrugsPageData(currentPage.value)
}

// Pagination methods
const handlePageChange = (page) => {
  getDrugsPageData(page)
}

// Initialize component
onMounted(() => {
  showCasePrescribeDrugsModal()
})
</script>
<style scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.3s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}

.modal {
  position: fixed;
  inset: 0;
  z-index: 50;
}

.show_modal_right {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.hideall {
  display: none;
}

.drawer {
  height: 100%;

}

/* Custom scrollbar */
.app_scrollbar {
  scrollbar-width: thin;
  scrollbar-color: rgba(156, 163, 175, 0.5) transparent;
}

.app_scrollbar::-webkit-scrollbar {
  width: 6px;
}

.app_scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.app_scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(156, 163, 175, 0.5);
  border-radius: 3px;
}

/* Animations */
.scale-in-modal {
  animation: scaleIn 0.3s ease-out forwards;
}

@keyframes scaleIn {
  0% {
    opacity: 0;
    transform: scale(0.95) translateY(10px);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

/* Custom Scrollbar */
.overflow-y-auto {
  scrollbar-width: thin;
  scrollbar-color: #E2E8F0 #F8FAFC;
}

.overflow-y-auto::-webkit-scrollbar {
  width: 6px;
}

.overflow-y-auto::-webkit-scrollbar-track {
  background: #F8FAFC;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  background-color: #E2E8F0;
  border-radius: 3px;
}

.overflow-y-auto::-webkit-scrollbar-thumb:hover {
  background-color: #CBD5E1;
}
</style>