<template>
  <div v-if="isVisible" class="fixed inset-0 z-50">
    <!-- Enhanced Backdrop with radial gradient -->
    <div 
      class="absolute inset-0 bg-gradient-to-br from-slate-900/60 to-green-900/40 backdrop-blur-md transition-all duration-500"
      @click="closeFowardModal"
    ></div>
    
    <!-- Drawer with enhanced animation -->
    <div class="absolute right-0 h-full w-[480px] flex flex-col bg-white/95 backdrop-blur-sm shadow-[0_0_50px_0_rgba(59,130,246,0.15)] transition-all duration-500">
      <!-- Enhanced Header Section -->
      <div class="px-8 py-6 bg-gradient-to-br from-white via-green-50 to-indigo-50/50">
        <div class="flex items-center justify-between">
          <div class="group">
            <h2 class="text-2xl font-bold bg-gradient-to-r from-green-600 to-indigo-600 bg-clip-text text-transparent
                       group-hover:from-indigo-600 group-hover:to-green-600 transition-all duration-300">
              Forward Case
            </h2>
            <p class="mt-1.5 text-sm text-slate-600">
              {{ filteredEmployees.length }} available staff members
            </p>
          </div>
          
          <div class="flex items-center gap-3">
            <button 
              @click="getOnlineEmployees(1)"
              class="relative p-2.5 text-slate-600 overflow-hidden rounded-full transition-all duration-300
                     hover:text-green-600 hover:bg-green-100 active:bg-green-200
                     before:content-[''] before:absolute before:inset-0 before:bg-green-100/0
                     before:hover:bg-green-100/50 before:transition-all before:duration-300"
              :class="{ 'animate-spin': loading === 'get_online_employees' }"
              title="Refresh List"
            >
              <svg class="w-5 h-5 relative z-10" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                      d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
              </svg>
            </button>
            <button 
              @click="closeFowardModal"
              class="relative p-2.5 text-slate-600 overflow-hidden rounded-full transition-all duration-300
                     hover:text-red-600 hover:bg-red-100 active:bg-red-200
                     before:content-[''] before:absolute before:inset-0 before:bg-red-100/0
                     before:hover:bg-red-100/50 before:transition-all before:duration-300"
              title="Close"
            >
              <svg class="w-5 h-5 relative z-10" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>

        <!-- Enhanced Search -->
        <div class="mt-6 relative group">
          <div class="absolute inset-y-0 left-4 flex items-center pointer-events-none
                      text-slate-400 group-focus-within:text-green-500 transition-colors duration-300">
            <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
          </div>
          <input
            v-model="searchTerm"
            type="text"
            placeholder="Search staff members..."
            class="w-full pl-12 pr-4 py-3.5 bg-white/70 border border-slate-200 rounded-2xl
                   placeholder:text-slate-400 focus:outline-none focus:ring-2 
                   focus:ring-green-500 focus:border-transparent transition-all duration-300
                   shadow-sm hover:border-slate-300 focus:bg-white"
          />
        </div>
      </div>

      <!-- Enhanced Staff List Section -->
      <div class="flex-1 overflow-y-auto px-8 py-6 [&::-webkit-scrollbar]:w-2 
                  [&::-webkit-scrollbar-track]:bg-slate-100 [&::-webkit-scrollbar-thumb]:bg-slate-300
                  [&::-webkit-scrollbar-thumb:hover]:bg-slate-400">
        <!-- Loading State -->
        <div 
          v-if="loading === 'get_online_employees'"
          class="flex flex-col items-center justify-center h-full"
        >
          <div class="relative w-16 h-16">
            <div class="absolute inset-0 rounded-full border-4 border-green-200"></div>
            <div class="absolute inset-0 rounded-full border-4 border-green-500 border-t-transparent animate-spin"></div>
          </div>
          <p class="mt-6 text-sm text-slate-600 font-medium">Loading staff members...</p>
        </div>

        <!-- Empty State -->
        <div 
          v-else-if="filteredEmployees.length === 0"
          class="flex flex-col items-center justify-center h-full text-center"
        >
          <div class="w-24 h-24 bg-gradient-to-br from-slate-100 to-green-50 rounded-full 
                      flex items-center justify-center mb-4 shadow-inner">
            <svg class="w-12 h-12 text-slate-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                    d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
            </svg>
          </div>
          <p class="text-slate-600 font-medium">No staff members available</p>
          <button 
            @click="getOnlineEmployees(1)"
            class="mt-4 text-green-600 hover:text-green-700 font-medium
                   relative after:content-[''] after:absolute after:bottom-0 after:left-0 
                   after:w-full after:h-0.5 after:bg-green-600 after:scale-x-0
                   hover:after:scale-x-100 after:transition-transform after:duration-300"
          >
            Refresh List
          </button>
        </div>

        <!-- Enhanced Staff List -->
        <div v-else class="space-y-4">
          <div
            v-for="emp in searchedEmployees"
            :key="emp.user_id"
            @click="selectEmployee(emp)"
            class="group relative p-4 rounded-2xl border-2 cursor-pointer transition-all duration-300
                   hover:shadow-lg hover:shadow-green-500/5"
            :class="{
              'border-green-500 bg-gradient-to-br from-green-50 via-indigo-50/50 to-white': formInput.user_id === emp.user_id,
              'border-slate-100 hover:border-green-200 hover:bg-slate-50/70': formInput.user_id !== emp.user_id
            }"
          >
            <div class="flex items-center gap-4">
              <!-- Enhanced Avatar -->
              <div 
                class="w-14 h-14 rounded-2xl bg-gradient-to-br from-green-500 to-indigo-600
                       flex items-center justify-center text-white font-semibold text-lg
                       shadow-lg shadow-green-500/20 group-hover:shadow-green-500/30
                       transition-all duration-300 group-hover:scale-105"
              >
                {{ getInitials(emp.first_name, emp.last_name) }}
              </div>
              
              <!-- Enhanced Info -->
              <div class="flex-1">
                <h3 class="font-semibold text-slate-900 group-hover:text-green-600 transition-colors duration-300">
                  {{ emp.first_name }} {{ emp.last_name }}
                </h3>
                <div class="mt-1.5 flex items-center gap-4 text-sm text-slate-600">
                  <div class="flex items-center gap-2">
                    <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                            d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
                    </svg>
                    {{ emp.attendance?.hospitalroom?.title || 'No Room' }}
                  </div>
                  <div class="flex items-center gap-2">
                    <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                            d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    {{ timeOnly(emp.attendance?.time_in) }}
                  </div>
                </div>
              </div>
            </div>

            <!-- Enhanced Selection Indicator -->
            <div 
              v-if="formInput.user_id === emp.user_id"
              class="absolute -right-2 -top-2 w-6 h-6 bg-gradient-to-br from-green-500 to-indigo-600 
                     rounded-full flex items-center justify-center shadow-lg shadow-green-500/30
                     animate-scale-in"
            >
              <svg class="w-4 h-4 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
              </svg>
            </div>
          </div>
        </div>
      </div>

      <!-- Enhanced Footer Section -->
      <div class="px-8 py-6 bg-gradient-to-br from-slate-50 via-green-50/30 to-indigo-50/20 border-t border-slate-100">
        <label class="block text-sm font-medium text-slate-700 mb-2">
          Comments
        </label>
        <div class="relative group">
          <textarea
            v-model="formInput.comments"
            rows="4"
            :maxlength="maxCommentLength"
            placeholder="Add any instructions or guidelines..."
            class="w-full p-4 bg-white/80 border border-slate-200 rounded-2xl resize-none
                   placeholder:text-slate-400 focus:outline-none focus:ring-2
                   focus:ring-green-500 focus:border-transparent transition-all duration-300
                   shadow-sm hover:border-slate-300 focus:bg-white group-hover:shadow-lg
                   group-hover:shadow-green-500/5"
          ></textarea>
          <span class="absolute bottom-3 right-3 text-xs text-slate-400 font-medium">
            {{ formInput.comments.length }}/{{ maxCommentLength }}
          </span>
        </div>

        <!-- Enhanced Error Message -->
        <p 
          v-if="formError.comments"
          class="mt-2 text-sm text-red-500 flex items-center gap-1.5 px-3 py-2 bg-red-50 rounded-lg"
        >
          <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          {{ formError.comments }}
        </p>

        <!-- Enhanced Forward Button -->
        <button
          @click="forwardPatientCase"
          :disabled="!canForward || loading === 'forward_patient_case'"
          class="mt-4 w-full py-3.5 px-4 bg-gradient-to-br from-green-500 via-green-600 to-indigo-600
                 text-white rounded-2xl font-medium shadow-lg shadow-green-500/20
                 hover:shadow-green-500/30 hover:from-green-600 hover:to-indigo-700
                 active:from-green-700 active:to-indigo-800 
                 disabled:opacity-50 disabled:cursor-not-allowed
                 transition-all duration-300 group"
        >
          <div class="relative flex items-center justify-center gap-2">
            <svg 
              v-if="loading === 'forward_patient_case'"
              class="w-5 h-5 animate-spin" 
              fill="none" 
              viewBox="0 0 24 24"
            >
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4" />
              <path class="opacity-75" fill="currentColor" 
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
            </svg>
            <span class="relative group-hover:translate-x-1 transition-transform duration-300">
              {{ getButtonText }}
            </span>
            <svg 
              v-if="!loading"
              class="w-4 h-4 opacity-0 group-hover:opacity-100 -translate-x-2 group-hover:translate-x-0
                     transition-all duration-300" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                    d="M13 7l5 5m0 0l-5 5m5-5H6" />
            </svg>
          </div>
        </button>

        <!-- Enhanced Helper Text -->
        <p 
          v-if="!canForward"
          class="mt-3 text-sm text-slate-600 text-center px-4 py-3 bg-slate-50 rounded-xl
                 border border-slate-100 shadow-sm"
        >
          <svg class="inline-block w-4 h-4 mr-1.5 text-slate-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          Please select a staff member to forward the case
        </p>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, computed, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

// Props
const props = defineProps({
  patientCaseId: {
    type: String,
    required: true
  },

  patientPositionId: {
    type: String,
    required: true
  }
})

// Emits
const emit = defineEmits(['closed'])

// Constants
const maxCommentLength = 500

// Reactive state
const isVisible = ref(false)
const searchTerm = ref('')
const formInput = reactive({
  hospitalroom_id: '',
  user_id: '',
  comments: ''
})

const formError = reactive({
  comments: ''
})

// Computed properties
const loading = computed(() => store.getters.loading)
const onlineEmployees = computed(() => store.getters.online_employees)
const authUser = computed(() => store.getters.authuser)
const clientLocation = computed(() => store.getters.clientlocation)

const filteredEmployees = computed(() => {
  if (!onlineEmployees.value?.data) return []
  return onlineEmployees.value.data.filter(emp => emp.user_id !== authUser.value.id)
})

const searchedEmployees = computed(() => {
  if (!searchTerm.value) return filteredEmployees.value
  const term = searchTerm.value.toLowerCase()
  return filteredEmployees.value.filter(emp => 
    emp.first_name.toLowerCase().includes(term) ||
    emp.last_name.toLowerCase().includes(term) ||
    emp.attendance?.hospitalroom?.title?.toLowerCase().includes(term)
  )
})

const canForward = computed(() => {
  return formInput.hospitalroom_id && formInput.user_id
})

const getButtonText = computed(() => {
  if (loading.value === 'forward_patient_case') return 'Forwarding...'
  if (!formInput.user_id) return 'Select a Staff Member'
  return 'Forward Case'
})

// Methods
const timeOnly = (timeString) => {
  if (!timeString) return ''
  try {
    return new Date(timeString).toLocaleTimeString([], { 
      hour: '2-digit', 
      minute: '2-digit'
    })
  } catch {
    return timeString
  }
}

const getInitials = (firstName, lastName) => {
  return `${firstName?.[0] || ''}${lastName?.[0] || ''}`
}

const getOnlineEmployees = async (page = 1) => {
  try {
    const data = {
      sort_field: '',
      sort_order: 'desc',
      per_page: 50,
      search: '',
      accessdata: JSON.stringify(clientLocation.value)
    }
    await store.dispatch('get_online_employees', { data, page })
  } catch (error) {
    console.error('Failed to fetch online employees:', error)
    store.dispatch('template_play_error_file', 'Failed to load online employees')
  }
}

const selectEmployee = (employee) => {
  formInput.hospitalroom_id = employee.attendance?.hospitalroom_id
  formInput.user_id = employee.user_id
}

const forwardPatientCase = async () => {
  if (!canForward.value) {
    store.dispatch('template_play_error_file', 'Please select a staff member')
    return
  }

  try {
    const data = {
      hospitalroom_id: formInput.hospitalroom_id,
      user_id: formInput.user_id,
      comments: formInput.comments.trim()
    }

    await store.dispatch('forward_patient_case', { 
      data,
      id: props.patientPositionId
    })
    
    store.dispatch('template_play_success_file', 'Case forwarded successfully')
    closeFowardModal()
  } catch (error) {
    console.error('Failed to forward case:', error)
    store.dispatch('template_play_error_file', 'Failed to forward case')
  }
}

const closeFowardModal = () => {
  isVisible.value = false
  setTimeout(() => {
    emit('closed')
  }, 300)
}

const showFowardModal = async () => {
  await getOnlineEmployees(1)
  isVisible.value = true
}

// Initialize on mount
onMounted(() => {
  showFowardModal()
})

// Auto-refresh list every 30 seconds
let refreshInterval
onMounted(() => {
  refreshInterval = setInterval(() => {
    if (isVisible.value) {
      getOnlineEmployees(1)
    }
  }, 30000)
})

onUnmounted(() => {
  if (refreshInterval) {
    clearInterval(refreshInterval)
  }
})
</script>

<style scoped>
@keyframes scale-in {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.animate-scale-in {
  animation: scale-in 0.2s ease-out;
}
</style>