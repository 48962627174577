export const namespaced = true 
import { comp_play_error_file, comp_play_success_file, comp_play_delete_file} from './utils';
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        trashes :'', 
        trash :'', 
    },
    
    getters : {
        trashes: (state )=> state.trashes,
        trash: (state )=> state.trash,
    },


    mutations : { 
        settrashes :(state,trashes ) => (state.trashes = trashes), 
        settrash :(state,trash ) => (state.trash = trash), 
    },

    actions : {

        async create_trash( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_trash')
            return new Promise((resolve, reject) => {
            axios.post("/settings/trashes/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('settrashes', response.data.data)
                toast.success("trash Added Successfully")
                }
                resolve(response)
                
            })
            .catch(async error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        }
                        
                    }
                }
                
                reject(error)
            });
            });	       
        },


        async get_trashes( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_trashes')
            return new Promise((resolve, reject) => {
            axios.post("/settings/trashes?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('settrashes', response.data.data)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_trash( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_trash' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/settings/updates/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('settrash', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async restore_trash_data( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'restore_trash_data' + id)
            return new Promise((resolve, reject) => {
            axios.post("/settings/trashes/restore/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('settrashes', response.data.data)
                toast.success("Data Restored  Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async change_trash_status({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_trash_status' + id)
            return new Promise((resolve, reject) => {
                axios.post(`/settings/trashes/status/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('settrashes', response.data.data)
                        if (data.status == 1) {
                            toast.success("trash Activated Successfully")
                        } else {
                            toast.success("trash Suspended Successfully")
                        }
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if(error.response.data.msg){
                                commit('seterror', error.response.data.msg)
                                comp_play_error_file(error.response.data.msg);
                            }else if(error.response.data.message){
                                commit('seterrors', error.response.data.message)
                                comp_play_error_file(error.response.data.message);
                            }
                        }
                    }
                    reject(error)
                });
            });
        },

        async delete_trash( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_trash' + id)
            return new Promise((resolve, reject) => {
            axios.post("/settings/trashes/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('settrashes', response.data.data)
                comp_play_delete_file('trash Deleted Successfully');
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    }
}